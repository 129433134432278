import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const App = () => {
  const { loginWithRedirect, logout, isAuthenticated, user } = useAuth0();

  // Enforce login-only behavior
  const loginOnly = async () => {
    await loginWithRedirect({
      screen_hint: "login", // Force login-only screen
      redirectUri: window.location.origin + "/callback", // Correct redirect URL
    });
  };

  // Redirect users attempting to access the signup flow
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get("screen_hint") === "signup") {
      // Redirect to the CMS signup page
      window.location.href = "https://your-cms-url.com/signup"; // Update with your CMS URL
    }
  }, []);

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: "#0e1116",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px",
        color: "#ffffff",
      }}
    >
      <header style={{ marginBottom: "50px", textAlign: "center" }}>
        <h1 style={{ fontSize: "2.5rem", fontWeight: "bold", marginBottom: "10px" }}>
          Welcome to Pipe Network
        </h1>
        <p style={{ fontSize: "1.2rem", color: "#9ca3af" }}>
          The future of decentralized content delivery.
        </p>
      </header>

      <main style={{ textAlign: "center" }}>
        {!isAuthenticated ? (
          <div>
            <button
              onClick={loginOnly}
              style={{
                background: "linear-gradient(90deg, #3a80f8, #4b96f7)",
                border: "none",
                padding: "10px 20px",
                fontSize: "1rem",
                borderRadius: "8px",
                color: "#ffffff",
                cursor: "pointer",
              }}
            >
              Log In
            </button>
          </div>
        ) : (
          <div>
            <p style={{ fontSize: "1.2rem", marginBottom: "20px" }}>
              Welcome, {user.name}!
            </p>
            <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
              <a
                href="/downloads/pipe-tool.zip"
                style={{
                  textDecoration: "none",
                  color: "white",
                }}
                download
              >
                <button
                  style={{
                    background: "linear-gradient(90deg, #3a80f8, #4b96f7)",
                    border: "none",
                    padding: "10px 20px",
                    fontSize: "1rem",
                    borderRadius: "8px",
                    color: "#ffffff",
                    cursor: "pointer",
                  }}
                >
                  Download Pipe Tool
                </button>
              </a>
              <a
                href="/downloads/dcdnd.zip"
                style={{
                  textDecoration: "none",
                  color: "white",
                }}
                download
              >
                <button
                  style={{
                    background: "linear-gradient(90deg, #3a80f8, #4b96f7)",
                    border: "none",
                    padding: "10px 20px",
                    fontSize: "1rem",
                    borderRadius: "8px",
                    color: "#ffffff",
                    cursor: "pointer",
                  }}
                >
                  Download DCDND
                </button>
              </a>
              <a
                href="/downloads/agent.zip"
                style={{
                  textDecoration: "none",
                  color: "white",
                }}
                download
              >
                <button
                  style={{
                    background: "linear-gradient(90deg, #3a80f8, #4b96f7)",
                    border: "none",
                    padding: "10px 20px",
                    fontSize: "1rem",
                    borderRadius: "8px",
                    color: "#ffffff",
                    cursor: "pointer",
                  }}
                >
                  Download Agent
                </button>
              </a>
            </div>
            <button
              onClick={() => logout({ returnTo: window.location.origin })}
              style={{
                marginTop: "20px",
                background: "#e63946",
                border: "none",
                padding: "10px 20px",
                fontSize: "1rem",
                borderRadius: "8px",
                color: "#ffffff",
                cursor: "pointer",
              }}
            >
              Log Out
            </button>
          </div>
        )}
      </main>
    </div>
  );
};

export default App;
